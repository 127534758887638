import React, { useState } from "react";
import { useQuery } from "react-query";
import CircularProgress from "@mui/material/CircularProgress";
import { Box, Grid, Typography, Tabs, Tab, Button } from "@mui/material";
import Profile from "./index";
import { useRecoilState } from "recoil";

// @ts-ignore
import { Link } from "react-router-dom";
import ContentList from "../ContentList";
import { useAuth0 } from "@auth0/auth0-react";
import { getUserTelegramConnection } from "../../apis/userApi";
import { searchContents } from "../../apis/searchContents";
import { telegramInfoState } from "../../store/atom";
import { useTelegram } from "../Telegram/useTelegram";

type Content = "image" | "template" | "meme" | "caption";

const ProfileContent: React.FC = () => {
  const { user } = useAuth0();
  const { user: telegramMiniAppUser } = useTelegram();
  const [telegramIdInfo] = useRecoilState(telegramInfoState);

  const [tabValue, setTabValue] = useState<Content>("image");

  const { data: userDataObj = {}, refetch: refetchUser } = useQuery(
    "getUserTelegramConnection",
    () =>
      getUserTelegramConnection({
        email: user?.email!!
      }),
    {
      enabled: false
    }
  );

  const userData = telegramIdInfo?.userData || userDataObj || {};

  const telegramId =
    telegramMiniAppUser?.id?.toString() || userData?.user_id || "";

  const {
    data = {},
    isLoading,
    isFetching
  } = useQuery(
    `searchContents-${telegramId}-${tabValue}`,
    () => searchContents(telegramId, tabValue),
    {
      enabled: !!telegramId,
      retry: false,
      refetchOnWindowFocus: false
    }
  );

  const handleTabChange = (value: Content) => {
    setTabValue(value);
  };

  const { contents = [], count = 0 } = data;

  return (
    <>
      <Box
        sx={{
          width: "100%",
          position: "relative",
          color: "white",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <Profile userData={userData} refetchUser={refetchUser} />
        </Box>
        {(user?.email_verified || !!telegramMiniAppUser?.id) && (
          <>
            <Box sx={{ mt: 3, width: "90%" }}>
              <Tabs
                value={tabValue}
                onChange={(_e, value) => handleTabChange(value)}
                aria-label="Contents Category tabs"
                sx={{
                  width: "100%",
                  ".MuiTab-root": {
                    color: "#ffffffcc",
                    padding: {
                      xs: "0px",
                      sm: "16px"
                    },
                    fontSize: {
                      xs: "11px",
                      sm: "14px"
                    }
                  },
                  ".Mui-selected": {
                    color: "#ffe263 !important",
                    padding: {
                      xs: "0px",
                      sm: "16px"
                    },
                    fontSize: {
                      xs: "11px",
                      sm: "14px"
                    }
                  }
                }}
              >
                <Tab label="Images" value={"image"} />
                <Tab label="Templates" value={"template"} />
                <Tab label="Captions" value={"caption"} />
                <Tab label="Memes" value={"meme"} />
              </Tabs>
            </Box>
            {isLoading || isFetching ? (
              <Box
                sx={{
                  margin: "0px 16px",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center"
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <Box sx={{ width: "90%", p: 2 }}>
                <Grid container spacing={2}>
                  {/* If TG not connected, show the recommendation message */}
                  {!telegramId ? (
                    <Grid item xs={12}>
                      <Typography variant="body1">
                        <b>
                          <p>
                            Connect your Telegram and get full access to meme
                            world while using Telegram!
                          </p>
                        </b>
                        By connecting your Telegram to memegram, sharing memes
                        are more easier!
                        <br />
                        <div style={{ opacity: 0.8 }}>
                          <b>Note: </b>
                          Go to DM of the bot by
                          <Link
                            to="https://t.me/memegramAIBot"
                            target="_blank"
                            style={{
                              color: "#ffe263",
                              marginLeft: "2px"
                            }}
                          >
                            clicking here
                          </Link>
                          . Then hit
                          <code style={{ color: "red", marginLeft: "4px" }}>
                            /id
                          </code>{" "}
                          and enter.
                        </div>
                        <Link to="https://t.me/memegramAIBot" target="_blank">
                          <Button
                            variant="outlined"
                            sx={{
                              marginTop: "16px",
                              textTransform: "none",
                              fontWeight: 700,
                              border: "1px solid #ffe263",
                              color: "#ffe263"
                            }}
                            size="small"
                          >
                            Telegram Bot 🤖
                          </Button>
                        </Link>
                      </Typography>
                    </Grid>
                  ) : (
                    <ContentList
                      data={contents}
                      isLoading={isLoading}
                      type={tabValue}
                    />
                  )}
                </Grid>
              </Box>
            )}
          </>
        )}
      </Box>
    </>
  );
};

export default ProfileContent;

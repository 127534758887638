import React from 'react';
import {
  Box,
  Button,
  Grid,
  Modal,
  Typography,
  TextField,
  CircularProgress,
} from '@mui/material';
import { useQuery } from 'react-query';
import VerifiedIcon from '@mui/icons-material/Verified';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import { useAuth0 } from '@auth0/auth0-react';
import { telegramInfoState } from '../../store/atom';
import { useRecoilState } from 'recoil';
import AuthButton from './Auth';
// @ts-ignore
import { truncate } from 'lodash';
import { Link } from 'react-router-dom';
import {
  createUserTelegramConnection,
  deleteUserTelegramConnection,
} from '../../apis/userApi';
import { useTelegram } from '../Telegram/useTelegram';

type ProfileProps = {
  userData: any;
  refetchUser: any;
};
const Profile: React.FC<ProfileProps> = ({ userData, refetchUser }) => {
  const { user, isAuthenticated, isLoading } = useAuth0();
  const { user: telegramMiniAppUser } = useTelegram();

  const [input, setInput] = useRecoilState(telegramInfoState);
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState('');

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setInput({ ...input, telegramId: '' });
    setError('');
  };

  const handleTelegramPrompt = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (
      value?.includes('-') ||
      value?.includes('@') ||
      value?.includes('https://') ||
      value?.includes('me')
    ) {
      setError('User telegram id incorrect');
      return;
    }
    setInput({ ...input, [name]: value });
    setError('');
  };

  const handleTelegramConnection = async () => {
    try {
      if (!(user?.email && input.telegramId)) {
        return;
      }
      await createUserTelegramConnection({
        email: user?.email,
        telegramId: input.telegramId,
      });
      await refetchUser();
    } catch (e) {
      //
    }
  };

  const handleTelegramDisconnection = async () => {
    try {
      if (!(user?.email && userData?.user_id)) {
        return;
      }
      await deleteUserTelegramConnection({
        email: user?.email,
        telegramId: userData?.user_id,
      });
      await refetchUser();
    } catch (e) {
      //
    }
  };

  /*  Render different view for Telegram mini app user */
  if (!!telegramMiniAppUser?.id) {
    return (
      <Box
        sx={{
          border: 'none',
          borderRadius: 4,
          backgroundColor: '#245c9c',
          width: {
            xs: '82%',
            sm: '90%',
          },
          padding: 2,
        }}
      >
        <Grid
          container
          spacing={2}
          sx={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <Grid item xs={12} sm={12} md={8}>
            <Box
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Box>
                {telegramMiniAppUser?.photo_url ? (
                  <Box
                    component="img"
                    src={telegramMiniAppUser?.photo_url}
                    alt={telegramMiniAppUser.first_name}
                    sx={{
                      borderRadius: '50%',
                      border: '2px solid #EEF263',
                      width: {
                        xs: '60px',
                        sm: '100px',
                      },
                      height: { xs: '60px', sm: '100px' },
                      objectFit: 'cover',
                      margin: 1,
                      mr: 3,
                    }}
                  />
                ) : (
                  <Box
                    sx={{
                      borderRadius: '50%',
                      width: '100px',
                      height: '100px',
                      backgroundColor: '#ffffff',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontSize: '2rem',
                      color: '#245c9c',
                      margin: 1,
                      mr: 3,
                    }}
                  >
                    {telegramMiniAppUser.first_name?.charAt(0).toUpperCase()}
                  </Box>
                )}
              </Box>
              <Box>
                <Typography
                  variant="h6"
                  sx={{ color: 'white', display: 'flex', alignItems: 'center' }}
                >
                  {truncate(
                    `${
                      telegramMiniAppUser.first_name ||
                      telegramMiniAppUser.username
                    } ${telegramMiniAppUser?.last_name || ''}`,
                    {
                      length: 18,
                    },
                  )}
                  {telegramMiniAppUser?.is_premium && (
                    <VerifiedIcon
                      sx={{ ml: '6px' }}
                      htmlColor="rgb(29, 180, 89)"
                    />
                  )}
                </Typography>
                <Typography variant="subtitle1" style={{ color: 'white' }}>
                  {telegramMiniAppUser.username
                    ? `@${telegramMiniAppUser.username}`
                    : ''}
                </Typography>
              </Box>
            </Box>
          </Grid>
          {!!telegramMiniAppUser?.id && (
            <Grid item xs={12} sm={12} md={4}>
              <Box>
                <Typography
                  variant="h6"
                  sx={{
                    color: 'white',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  Telegram connection
                  <CheckCircleIcon
                    sx={{ ml: '6px' }}
                    htmlColor="rgb(29, 180, 89)"
                  />
                </Typography>
                <Typography variant="subtitle1" style={{ color: 'white' }}>
                  Telegram Username:{' '}
                  <Link
                    to={`https://t.me/${telegramMiniAppUser?.username}`}
                    target="_blank"
                    style={{
                      color: 'inherit',
                      padding: '2px',
                      textUnderlineOffset: '4px',
                    }}
                  >
                    @{telegramMiniAppUser?.username}
                  </Link>
                </Typography>
                <Typography variant="subtitle1" style={{ color: 'white' }}>
                  Telegram Id: <code>{telegramMiniAppUser.id}</code>
                </Typography>
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>
    );
  }

  if (isLoading) {
    return (
      <CircularProgress
        sx={{ margin: '0 auto', marginTop: '16px', color: '#3c6dc6' }}
      />
    );
  }

  if (!user || !isAuthenticated) {
    return (
      <Box
        sx={{
          border: 'none',
          borderRadius: 5,
          backgroundColor: '#245c9c',
          opacity: 0.9,
          padding: 2,
          height: '100px',
          width: {
            xs: '70%',
            sm: '40%',
          },
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div>
          <Typography sx={{ margin: '8px 0px' }}>
            Log in to view and track your generated contents.
          </Typography>
          <AuthButton />
        </div>
      </Box>
    );
  }

  const telegramUserId = userData?.user_id ?? '';

  return (
    <Box
      sx={{
        border: 'none',
        borderRadius: 4,
        backgroundColor: '#245c9c',
        width: {
          xs: '82%',
          sm: '90%',
        },
        padding: 2,
      }}
    >
      <Grid
        container
        spacing={2}
        sx={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <Grid item xs={12} sm={12} md={8}>
          <Box
            sx={{
              height: '100%',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Box>
              {user.picture || telegramMiniAppUser?.photo_url ? (
                <Box
                  component="img"
                  src={telegramMiniAppUser?.photo_url || user.picture}
                  alt={user.name}
                  sx={{
                    borderRadius: '50%',
                    border: '2px solid #EEF263',
                    width: {
                      xs: '60px',
                      sm: '100px',
                    },
                    height: { xs: '60px', sm: '100px' },
                    objectFit: 'cover',
                    margin: 1,
                    mr: 3,
                  }}
                />
              ) : (
                <Box
                  sx={{
                    borderRadius: '50%',
                    width: '100px',
                    height: '100px',
                    backgroundColor: '#ffffff',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: '2rem',
                    color: '#245c9c',
                    margin: 1,
                    mr: 3,
                  }}
                >
                  {user.name?.charAt(0).toUpperCase()}
                </Box>
              )}
            </Box>
            <Box>
              <Typography
                variant="h6"
                sx={{ color: 'white', display: 'flex', alignItems: 'center' }}
              >
                {truncate(user.name, {
                  length: 18,
                })}
                <VerifiedIcon sx={{ ml: '6px' }} htmlColor="rgb(29, 180, 89)" />
              </Typography>
              <Typography variant="subtitle1" style={{ color: 'white' }}>
                {user.email}
              </Typography>
              {/* Show connect telegram button when not connected */}
              {!telegramUserId && (
                <Box sx={{ textAlign: 'left', marginTop: '8px' }}>
                  <Button
                    size="small"
                    variant="outlined"
                    sx={{
                      color: '#FFF263',
                      border: '1px solid #FFE263',
                    }}
                    onClick={handleOpen}
                  >
                    Connect Telegram
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </Grid>
        {!!telegramUserId && (
          <Grid item xs={12} sm={12} md={4}>
            <Box>
              <Typography
                variant="h6"
                sx={{
                  color: 'white',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                Telegram connection
                <CheckCircleIcon
                  sx={{ ml: '6px' }}
                  htmlColor="rgb(29, 180, 89)"
                />
              </Typography>
              <Typography variant="subtitle1" style={{ color: 'white' }}>
                Telegram Username:{' '}
                <Link
                  to={`https://t.me/${userData?.username}`}
                  target="_blank"
                  style={{
                    color: 'inherit',
                    padding: '2px',
                    textUnderlineOffset: '4px',
                  }}
                >
                  @{userData?.username}
                </Link>
              </Typography>
              <Typography variant="subtitle1" style={{ color: 'white' }}>
                Telegram Id: <code>{userData.user_id}</code>
              </Typography>
              <Button
                size="small"
                variant="outlined"
                sx={{
                  color: '#ff3333',
                  border: '1px solid #FFE263',
                  textTransform: 'none',
                  opacity: 0.9,
                }}
                onClick={handleTelegramDisconnection}
              >
                Disconnect
              </Button>
            </Box>
          </Grid>
        )}
      </Grid>
      {/* Connection Model */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={({ breakpoints }) => ({
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: '#36454F',
            color: 'white',
            boxShadow: 24,
            width: 400,
            p: 4,
            maxWidth: '60%',
            [breakpoints.down('sm')]: {
              width: '100%',
            },
            height: 'auto',
            maxHeight: '80%',
            overflow: 'auto',
          })}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row' }}>
              <Typography id="modal-modal-title" variant="h6">
                Connect Telegram
              </Typography>
              <CloseIcon
                sx={{
                  ml: 'auto',
                  fontSize: 30,
                  cursor: 'pointer',
                  '&:hover': {
                    color: 'red',
                  },
                }}
                onClick={handleClose}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                <b>Add your Telegram Id</b>
              </Typography>
              <Typography variant="body2">
                You can get your Telegram Id by following the below instruction.
                <div>
                  <b>1.</b> Open the Memegram Telegram bot
                  <Link
                    to="https://t.me/memegramAIBot"
                    target="_blank"
                    style={{
                      color: '#ffe263',
                      marginLeft: '2px',
                    }}
                  >
                    Click here
                  </Link>
                </div>
                <div>
                  <b>2.</b> When you are within the Telegram bot, enter{' '}
                  <code style={{ color: 'red' }}>/id</code>
                </div>
                <div>
                  <b>3.</b> Memegram bot displays your telegram id.
                </div>
                <div>
                  <b>4.</b> Copy the code and paste below in the text field.
                </div>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                size="small"
                autoComplete="off"
                placeholder="Telegram Id"
                InputLabelProps={{ style: { color: '#ffe263' } }}
                sx={{
                  mb: 2,
                  height: 'auto',
                  borderRadius: 1,
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#ffe263',
                    },
                    '&:hover fieldset': {
                      borderColor: '#ffe263',
                    },
                  },
                }}
                inputProps={{ style: { color: 'white' } }}
                name="telegramId"
                onChange={handleTelegramPrompt}
                error={error.length > 0}
                helperText={error}
              />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <Button
                  size="small"
                  variant="outlined"
                  sx={{
                    color: '#FFF263',
                    border: '1px solid #FFE263',
                    '&.Mui-disabled': {
                      color: '#a9a9a9',
                      borderColor: '#a9a9a9',
                    },
                  }}
                  onClick={() => {
                    handleTelegramConnection();
                    handleClose();
                  }}
                  disabled={!(input.telegramId && !error)}
                >
                  Connect
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </Box>
  );
};

export default Profile;
